<template>
	<v-sheet flat>
		<h2 class="mb-4">Overview</h2>
		<h4 class="mb-4">Participating Organisations - Activity Report</h4>
		<p>
			The table on this page provides an overview over each participating
			organisation's progress in terms of the average rate of completion for
			assessment sections available to them, the number of sections completed
			and the number of sections not yet complete.
		</p>
		<p>
			You can access further organisation-specific information by clicking on
			the name of the organisation you're interested in.
		</p>
		<v-text-field :label="$t('misc.search')" v-model="search"></v-text-field>
		<v-data-table
			disable-pagination
			:search="search"
			:headers="headers"
			:items="items"
			@click:row="clickRow"
		>
			<template v-slot:item.rate="{ item }"> {{ item.rate }}% </template>
		</v-data-table>
	</v-sheet>
</template>

<script>
import Vuex from "vuex";
export default {
	name: "Overview",
	data: () => {
		return {
			search: "",
			columnAgg: "section",
		};
	},
	computed: {
		...Vuex.mapState({
			organisations: (state) => state.organisations.data,
			assessments: (state) => state.assessments.data,
			sections: (state) => state.sections.data,
			sectionResponses: (state) => state.sectionResponses.data,
			config: (state) => state.config.data,
		}),
		themes() {
			return this.$store.getters["themes/options"];
		},
		organisationResponses() {
			const self = this;
			return Object.fromEntries(
				Object.keys(this.organisations).map((o) => {
					let responses = self.$store.getters
						.organisationResponses(o)
						.map((item) => {
							return self.sectionResponses[item.response];
						});
					return [o, responses];
				})
			);
		},
		items() {
			const self = this;
			let rows = Object.keys(self.organisations).map((id) => {
				let leadRespondent = self.$store.getters.organisationLeadRespondent(
					id
				);
				let row = {
					id: id,
					organisation: self.organisations[id]
						? self.organisations[id].name
						: "",
					leadRespondent: leadRespondent ? leadRespondent.displayName : "",
				};
				let responses = self.organisationResponses[id] || [];
				responses = responses.filter((r) => r);
				let rates = responses.map((r) => r.completion_rate);
				row.rate = self.mwutils.average(rates).toFixed();
				row.complete = responses.filter(
					(r) => r.status == "complete"
				).length;
				row.outstanding = responses.length - row.complete;
				return row;
			});
			return rows.filter((r) => r);
		},
		headers() {
			let headers = [
				// { text: "", value: "remind" },
				{ text: "Organisation", value: "organisation" },
				{ text: "Lead Respondent", value: "leadRespondent" },
				{ text: "Average Section Completion Rate", value: "rate" },
				{ text: "Sections Completed", value: "complete" },
				{ text: "Sections Outstanding", value: "outstanding" },
			];
			return headers;
		},
	},
	methods: {
		clickRow(row) {
			this.$router.push({
				name: "OrganisationDetail",
				params: { id: row.id },
			});
		},
	},
};
</script>
